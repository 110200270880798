
    var riot = require('riot')
    
riot.tag2('bramblemap', '<gmap-markers markers="{markers1}" linkedmap="{opts.linkedmap}"></gmap-markers>', '', '', function(opts) {
this.markers1 = [
  {
    name: "WA8 8LD",
    latitude: 53.3707557,
    longitude: -2.7824235
  }
];
});
    
  