
    var riot = require('riot')
    
riot.tag2('whisper', '<div onclick="{openModal}" class="sidebar-header purple"><img src="/images/assets/whisper-icon.png" width="28" height="28" style="margin-right: 5px">Whisper</div> <modal name="whispermodal" onclose="{closeModal}" title="Whisper" savetext="Send Comment" onsave="{sendRequest}" esckey="{true}"> <form class="form-horizontal"> <div class="row"> <div class="col-xs-12"> <forminput name="child_name" value="{parent.data.name}" onupdate="{parent.handleUpdate}" label="Child\'s name"></forminput> <forminput name="year" value="{parent.data.year}" onupdate="{parent.handleUpdate}" label="Year"></forminput> <formselect default="Please Select" name="gender" value="{parent.data.gender}" onupdate="{parent.handleUpdate}" label="Gender" isrequired options="{[{label:\'Girl\',value:\'girl\'},{label:\'Boy\', value:\'boy\'}]}"></formselect> <formtextarea name="comments" value="{parent.data.comments}" onupdate="{parent.handleUpdate}" label="Comments" isrequired></formtextarea> </div> <div class="col-xs-12"> <div class="form-group"> <div class="col-xs-8 col-xs-offset-4"> <recaptcha key="{parent.opts.key}" onupdate="{parent.tokenUpdate}" name="whisper_recaptcha"></recaptcha> </div> </div> </div> </div> <div class="row"> <div class="col-sm-8 col-sm-offset-4"> <div if="{parent.accepted}" class="alert alert-success"> <p>Thank you, your comments have been submitted</p> </div> <div if="{parent.rejected}" class="alert alert-danger"> <p>There was a problem sending your comments, please try again later.</p> </div> </div> </div> </form> </modal>', '', '', function(opts) {
var api, resource;

resource = require('riot-kit/utils/apiResource.coffee');

api = new resource('/duocms/api/whisper', true);

this.data = {
  child_name: "",
  year: "",
  gender: "",
  comments: ""
};

this.on('mount', (function(_this) {
  return function() {
    return console.log('mount whisper');
  };
})(this));

this.openModal = (function(_this) {
  return function() {
    return _this.tags.whispermodal.show();
  };
})(this);

this.closeModal = (function(_this) {
  return function() {};
})(this);

this.openThankYouModal = (function(_this) {
  return function() {
    return _this.tags.thankyoumodal.show();
  };
})(this);

this.tokenUpdate = (function(_this) {
  return function(token) {
    _this.token = token;
  };
})(this);

this.sendRequest = (function(_this) {
  return function() {
    _this.data.token = _this.token;
    console.log('data', _this.data);
    return api.save(_this.data, function(data, err) {
      if (err != null) {
        _this.rejected = true;
        console.log('whisper unsucessfull', err);
        return;
      }
      _this.accepted = true;
      console.log('whisper successful', data);
      return _this.reset();
    });
  };
})(this);

this.handleUpdate = (function(_this) {
  return function(row) {
    var ref, ref1;
    if (row != null) {
      if ((ref = row.name) != null) {
        if ((ref1 = ref.split(".")) != null) {
          ref1.reduce(function(acc, cur, idx, src) {
            if (idx === src.length - 1) {
              acc[cur] = row.value;
            }
            return acc[cur];
          }, _this.data);
        }
      }
    }
    if (_this.debounceUpdate) {
      clearTimeout(_this.debounceUpdate);
    }
    return _this.debounceUpdate = setTimeout(function() {
      return _this.update();
    }, 200);
  };
})(this);

this.reset = (function(_this) {
  return function() {
    _this.data = {
      child_name: "",
      year: "",
      gender: "",
      comments: ""
    };
    _this.token = null;
    _this.tags['whispermodal'].tags['whisper_recaptcha'].reset();
    _this.update();
    if (_this.debounceReset) {
      clearTimeout(_this.debounceReset);
    }
    return _this.debounceReset = setTimeout(function() {
      _this.accepted = false;
      _this.rejected = false;
      _this.update();
      return _this.tags.whispermodal.close();
    }, 2000);
  };
})(this);
});
    
  