window.riot = riot = require 'riot'

require 'riot-kit/lib/slideshow'
require 'riot-kit/lib/datepicker'
require 'riot-kit/lib/eventlist'
require 'riot-kit/lib/eventcalendar'
require 'riot-kit/lib/dateformat'
require 'riot-kit/lib/form'
require 'riot-kit/lib/dropdown'
require 'riot-kit/lib/gmap'
# require 'riot-kit/lib/ical'
require 'riot-kit/lib/recaptcha'

require './tags/social-share'
require './tags/navigation.tag'
require './tags/duomap.tag'
require './tags/twitter.tag'
require './tags/bramblemap.tag'
require './tags/ticker.tag'
require './tags/whisper.tag'
require './libs/slideshow.coffee'

require './libs/slideshow.coffee'

riot.mount('*')
document.addEventListener 'duocms:updated', ->
  riot.mount('slideshow,gmap')

# loads css async
[].slice.call(document.querySelectorAll("link[media=none]")).forEach (stl)-> stl.setAttribute("media","all")
[].slice.call(document.querySelectorAll('input')).forEach (input)-> 
  input.setAttribute("style","display:none") if input.getAttribute("name")?.match(/^duoreform/)

togglenav = ->
  if nav.className.indexOf('collapsed')>-1 || nav2.className.indexOf('collapsed')>-1
    nav.className = nav.className.replace(/ ?toggle-on ?/,'')
    nav2.className = nav2.className.replace(/ ?toggle-on ?/,'')
  else
    nav.className = nav.className.replace(/ ?toggle-on ?/,'')+' toggle-on'
    nav2.className = nav2.className.replace(/ ?toggle-on ?/,'')+' toggle-on'

nav = document.querySelector('.navicon')
nav2 = document.querySelector('.navicon-open')
nav.addEventListener 'click', (e)->
  togglenav()
nav2.addEventListener 'click', (e)->
  togglenav()

msnry = ''

window.onload = ->

  setTimeout ->
    if $('.twitter-inner').length
      msnry = $('.twitter-inner').masonry({
        itemSelector:'.item',
        columnWidth:'.grid-sizer',
        percentPosition:true
      })
      imgLoad = imagesLoaded('.twitter-inner')
      imgLoad.on 'progress',=>
        msnry.masonry('layout')
      imgLoad.on 'always',=>
        ti = document.querySelectorAll('.twitter-inner,.twitter-loading')
        [].forEach.call ti,(t)->
          t.className = t.className.replace(/ ?loaded ?/,'')+' loaded'
    if $('.twitter-ctas-inner').length
      imgLoad = imagesLoaded('.twitter-ctas-inner')
      imgLoad.on 'always',=>
        ti = document.querySelectorAll('.twitter-ctas-inner,.twitter-ctas-loading')
        [].forEach.call ti,(t)->
          t.className = t.className.replace(/ ?loaded ?/,'')+' loaded'
  ,1000